import api from '../api';
import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { TiTicket } from "react-icons/ti";
import NavbarHome from './NavbarHome';
import { AuthContext } from '../context/AuthContext';
import Swal from 'sweetalert2';

const ReportForm = () => {
  const { user } = useContext(AuthContext);
  const [fullName, setFullname] = useState('');
  const [phoneNumber, setPhonenumber] = useState('');
  const [detail, setDetail] = useState(''); // Correct spelling
  const [purpose, setPurpose] = useState('');
  const [file, setFile] = useState(null);

  const purposeOptions = [
    'แจ้งปัญหาการใช้งาน​',
    'แจ้งเรื่องร้องเรียน',
    'แจ้งปัญหาอื่นๆ'
  ];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const result = await Swal.fire({
      title: 'ยืนยันการส่งข้อมูล?',
      text: 'กรุณาตรวจสอบข้อมูลให้ครบถ้วนก่อนส่ง!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ใช่, ส่งข้อมูล',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    });

    if (result.isConfirmed) {
      const formData = new FormData();
      formData.append('fullName', fullName);
      formData.append('phoneNumber', phoneNumber);
      formData.append('detail', detail);
      formData.append('purpose', purpose);
      if (file) {
        formData.append('file', file);
      }

      try {
        const response = await api.post('/complaints', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        Swal.fire({
          title: 'สำเร็จ!',
          text: 'ส่งข้อมูลเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        });
        // Clear form fields
        setFullname('');
        setPhonenumber('');
        setDetail('');
        setPurpose('');
        setFile(null);
      } catch (error) {
        Swal.fire({
          title: 'ข้อผิดพลาด!',
          text: error.response.data.error,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        });
      }
    }
  };

  return (
    <>
      <NavbarHome />
      <Container fluid className="p-4" style={{ backgroundColor: '#F5F5F5' }}>
        <Row className="mb-4">
          <Col>
            <h2 className="text-primary text-center"><TiTicket /> แจ้งปัญหาและเรื่องร้องเรียน</h2>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={4}>
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Card.Title className="text-primary">วิธีการแจ้งปัญหาง่ายๆให้เรารับรู้</Card.Title>
                <Card.Text className="text-muted text-start">
                  1. เลือกหัวข้อที่ต้องการแจ้ง
                  <ul>
                    <li>แจ้งปัญหาการใช้งาน: สำหรับปัญหาระบบการใช้งานและการส่งข้อความ</li>
                    <li>แจ้งเรื่องร้องเรียน: ระบุปัญหาที่พบเจอพร้อมแนบหลักฐานปัญหา</li>
                    <li>แจ้งปัญหาอื่นๆ: สำหรับปัญหาทั่วไปที่ไม่เกี่ยวกับผลิตภัณฑ์และบริการ</li>
                  </ul>
                  2. กรอกข้อมูลติดต่อ <br />
                  3. กรอกรายละเอียดต่างๆของการแจ้ง <br />
                  4. กดยืนยันและส่งข้อมูล
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formPurpose" className="mb-3">
                <Form.Label>หัวข้อที่ต้องการแจ้ง</Form.Label>
                <Form.Control
                  as="select"
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                  required
                >
                  <option value="">เลือกหัวข้อ</option>
                  {purposeOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formFullName" className="mb-3">
                <Form.Label>ชื่อจริง (ชื่อ - นามสกุล)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="กรอกชื่อจริง"
                  value={fullName}
                  onChange={(e) => setFullname(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhoneNumber" className="mb-3">
                <Form.Label>เบอร์โทรศัพท์</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="กรอกเบอร์โทรศัพท์"
                  value={phoneNumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDetail" className="mb-3">
                <Form.Label>รายละเอียดการติดต่อ</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="กรอกรายละเอียด"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formFileUpload" className="mb-3">
                <Form.Label>รูปภาพรายละเอียดเพิ่มเติม</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                ยืนยันการส่งข้อมูล
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportForm;