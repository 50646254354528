import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Container, NavDropdown, Row, Col, Badge } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthContext } from '../context/AuthContext';
import './DashboardNavbar.css';
import logo from '../assets/logo.png';
import { FaLine, FaUserTag, FaBell, FaShoppingBasket } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import { MdDashboardCustomize } from "react-icons/md";
import api from '../api';

const DashboardNavbar = () => {
  const { user, logout } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.get(`/user-info/${user.id}`);
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const fetchNotifications = async () => {
      try {
        const response = await api.get(`/notifications/${user.email}`);
        setNotifications(response.data || []);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (user && user.id) {
      fetchUserInfo();
      fetchNotifications();
    }
  }, [user]);

  const handleClickContact = () => {
    window.open('https://line.me/R/ti/p/@siamsms', '_blank');
  };

  return (
    <>
      <div className="contact-bar">
        <Container>
          <Row>
            <Col className="ms-auto text-end">
              <span>Call Center : 08-002-03350</span>
              <span className="line-contact" onClick={handleClickContact} style={{ cursor: 'pointer' }}><FaLine /> @siamsms</span>
            </Col>
          </Row>
        </Container>
      </div>

      <Navbar expand="lg" className="custom-navbar">
        <Container>
          <LinkContainer to="/dashboard">
            <Navbar.Brand>
              <img
                style={{ marginRight: '1rem' }}
                src={logo}
                width="200"
                height="45"
                className="d-inline-block align-center"
                alt="SMSMKT logo"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/dashboard">
                <Nav.Link><MdDashboardCustomize /> แดชบอร์ด</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/sendsms">
                <Nav.Link><BiMailSend /> ส่งข้อความ</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/sendername">
                <Nav.Link><FaUserTag /> ขอชื่อผู้ส่ง</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/order">
                <Nav.Link><FaShoppingBasket /> สั่งซื้อแพ็คเกจ</Nav.Link>
              </LinkContainer>
            </Nav>
            {userInfo && (
              <Nav className="ms-auto">
                <Nav.Link>เครดิต {userInfo.credits}</Nav.Link>
                <NavDropdown title={userInfo.email} id="user-nav-dropdown">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>ข้อมูลส่วนตัว</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/orderlog">
                    <NavDropdown.Item>ประวัติการสั่งซื้อ</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logout}>ออกจากระบบ</NavDropdown.Item>
                </NavDropdown>

                {/* Notification dropdown */}
                <NavDropdown title={<><FaBell /> {notifications.length > 0 && <Badge bg="danger">{notifications.length}</Badge>}</>} id="notification-nav-dropdown">
                  {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                      <NavDropdown.Item key={index}>
                        {notification.messages}
                      </NavDropdown.Item>
                    ))
                  ) : (
                    <NavDropdown.Item>ไม่มีการแจ้งเตือน</NavDropdown.Item>
                  )}
                </NavDropdown>

              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default DashboardNavbar;