import React, { useState } from 'react';
import { Card, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { FaShoppingBasket } from "react-icons/fa";
import scb_logo from '../assets/scb-logo.png';
import './Page_Price.css';
import DashboardNavbar from "./DashboardNavbar";
import Swal from 'sweetalert2';
import api from '../api';

const Orderpacket = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [slipFile, setSlipFile] = useState(null);

  const plans = [
    {
      name: 'START',
      price: 1000,
      costPerMessage: '0.3 บาท/ข้อความ',
      messages: '3,333 ข้อความ',
      duration: '12 เดือน',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'secondary'
    },
    {
      name: 'BASIC',
      price: 5000,
      costPerMessage: '0.25 บาท/ข้อความ',
      messages: '20,000 ข้อความ',
      duration: '12 เดือน',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary'
    },
    {
      name: 'STANDARD',
      price: 10000,
      costPerMessage: '0.22 บาท/ข้อความ',
      messages: '50,000 ข้อความ',
      duration: '24 เดือน',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary',
      recommended: true
    },
    {
      name: 'PRO',
      price: 50000,
      costPerMessage: '0.2 บาท/ข้อความ',
      messages: '250,0000 ข้อความ',
      duration: '36 เดือน',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary'
    },
    {
      name: 'ADVANCE',
      price: 100000,
      costPerMessage: '0.18 บาท/ข้อความ',
      messages: '555,555 ข้อความ',
      duration: '36 เดือน',
      senders: 'ไม่จำกัดชื่อผู้ส่ง',
      credit: 'คืนเครดิตถ้าส่งไม่ถึงผู้รับ',
      buttonVariant: 'primary'
    }
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleFileChange = (event) => {
    setSlipFile(event.target.files[0]);
  };

  const handleOrder = async () => {
    if (!selectedPlan || !slipFile) {
      Swal.fire({
        icon: 'warning',
        title: 'กรุณาเลือกแพ็คเกจและอัปโหลดสลิปโอนเงิน'
      });
      return;
    }
  
    const formData = new FormData();
    formData.append('plan', selectedPlan.name);
    formData.append('price', selectedPlan.price);
    formData.append('slip', slipFile);
  
    const result = await Swal.fire({
      title: 'ยืนยันส่งการสั่งซื้อ?',
      text: "กรุณาตรวจสอบข้อมูลก่อนยืนยัน!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    });
  
    if (result.isConfirmed) {
      try {
        const response = await api.post('/order', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'สั่งซื้อสำเร็จ',
            text: 'เราได้รับข้อมูลการสั่งซื้อแล้ว!'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: response.data.message
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถสั่งซื้อได้'
        });
      }
    }
  };  

  return (
    <>
      <DashboardNavbar />

      <Container className="mt-5 text-center">
        <Col>
          <h2 className="text-primary text-center"><FaShoppingBasket /> สั่งซื้อแพ็คเกจ</h2>
        </Col>
        <p className="text-center text-success">
          *เครดิตที่ส่งไม่สำเร็จจะทำการคืนเครดิตภายใน 48 ชั่วโมง
        </p>
        <Row className="justify-content-center">
          {plans.map((plan, index) => (
            <Col key={index} md={2} className="mb-4-custom">
              <Card 
                className={`text-center card-custom ${selectedPlan?.name === plan.name ? 'border-success-custom' : ''}`}
              >
                {plan.recommended && (
                  <div className="recommended-label">แนะนำ</div>
                )}
                <Card.Body>
                  <Card.Title className="card-title-custom">{plan.name}</Card.Title>
                  <Card.Subtitle className="mb-2 card-subtitle-custom text-muted">฿{Number(plan.price).toLocaleString()}</Card.Subtitle>
                  <Card.Text className="card-text-custom">
                    <small>{plan.costPerMessage}</small><br />
                    <small>{plan.messages}</small><br />
                    <small>{plan.duration}</small><br />
                    <small>{plan.senders}</small><br />
                    <small>{plan.credit}</small>
                  </Card.Text>
                  <Button
                    variant={plan.buttonVariant}
                    onClick={() => handlePlanSelect(plan)}
                  >
                    เลือกแพ็คเกจ
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>


        {selectedPlan && (
          <div className="mt-4">
            <h4>สั่งซื้อแพ็คเกจ {selectedPlan.name}</h4>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>รายละเอียดการโอนเงิน</Card.Title>
                <Card.Text style={{ fontSize: '1rem' }}>
                  <img
                    src={scb_logo}
                    alt="Bank Logo"
                    style={{ width: '50px', marginRight: '10px', borderRadius: '0.5rem' }}
                  /><br />
                  ธนาคาร ไทยพาณิชย์<br />
                  เลขที่บัญชี: 403-248-1991<br />
                  ชื่อบัญชี: บริษัท พี.บี. คัลเลอร์ ซัพพลาย 2012 จำกัด
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>จำนวนยอดเงินที่ต้องชำระ</Card.Title>
                <Card.Text style={{ fontSize: '1rem' }}>
                  {selectedPlan && !isNaN(selectedPlan.price) ? (
                    `฿${(Number(selectedPlan.price) + (Number(selectedPlan.price) * 7) / 100).toLocaleString()}`
                  ) : 'ไม่มีข้อมูล'}
                  <p className="text-muted">
                  (ราคารวมภาษีมูลค่าเพิ่ม 7 %)
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>อัปโหลดสลิป</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>

            <Button onClick={handleOrder} variant="success">ส่งคำสั่งซื้อ</Button>
          </div>
        )}

        <Row className="mt-5 gy-3" md={1} xs={1} style={{ marginBottom: '2.5rem' }}>
          {/* Additional information and notes as before */}
        </Row>
      </Container>
    </>
  );
};

export default Orderpacket;