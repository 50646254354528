import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css'; // Import custom CSS for styling
import logo from '../assets/logo.png'; // Import the logo
import { FaLine, FaTiktok, FaInstagram, FaFacebook, FaMailBulk } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <img
              src={logo}
              width="200"
              height="auto"
              className="d-inline-block align-top"
              alt="SIAM-SMS logo"
            />
          </Col>
          <Col md={4}>
            <h5>เกี่ยวกับเรา</h5>
            <ul>
              <li><a href="/price">ราคา</a></li>
              <li><a href="/terms">ข้อกำหนดและเงื่อนไขการใช้บริการ</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>ช่องทางการติดต่อ</h5>
            <ul>
              <li><FaLine/> <a href='#'>@siamsms</a></li>
              <li><FaTiktok/> <a href='#'>siam.sms</a></li>
              <li><FaInstagram/> <a href='#'>siam_sms</a></li>
              <li><FaFacebook/> <a href='#'>Siam-sms</a></li>
              <li><FaMailBulk/> <a href='#'>siam.sms111@gmail.com</a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p>&copy; {new Date().getFullYear()} SIAM-SMS. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;