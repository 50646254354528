import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import NavbarHome from './NavbarHome';

const Terms = () => {
  return (
    <>
      <NavbarHome />

      <Container className="mt-5">
        <h1 className="text-center mb-4">ข้อกำหนดและเงื่อนไขการใช้บริการ</h1>
        <p className="text-center text-success">
          *โปรดอ่านและทำความเข้าใจ หากมีข้อสงสัยใดๆ <a href="#">กรุณาติดต่อเรา</a>
        </p>
        <Row className="justify-content-center mt-5">
          <Col lg={8}>
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Card.Title className="text-primary">1. การลงทะเบียนและการใช้สิทธิ</Card.Title>
                <Card.Text className="text-muted text-start">
                  ในการลงทะเบียนขอใช้ Sender Name ที่เป็นเครื่องหมายการค้า และ/หรือ สิทธิในทางทรัพย์สินทางปัญญา
                  ของบุคคล และ/หรือ นิติบุคคล อื่นใด ผู้ใช้บริการต้องแนบหนังสือยินยอมจากบุคคลหรือนิติบุคคลนั้น
                  มากับเอกสารนี้ด้วย:
                  <ul>
                    <li>ข้อความสำคัญแจ้งเตือนจากรัฐบาล เช่น ข้อมูลภัยพิบัติ</li>
                    <li>ระบบสมาชิก เช่น การยืนยันวันเวลานัดหมาย การใช้ points ที่สะสมเพื่อแลกของรางวัล</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Card.Title className="text-primary">2. ความรับผิดชอบของผู้ใช้บริการ</Card.Title>
                <Card.Text className="text-muted text-start">
                  ผู้ใช้บริการจะต้องรับผิดชอบในเรื่องเครื่องหมายการค้า และ/หรือ สิทธิในทรัพย์สินทางปัญญาของ Sender Name ที่ประสงค์จะขอใช้กับทางผู้ให้บริการทุกประการ
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Card.Title className="text-primary">3. การใช้ Sender Name อย่างเหมาะสม</Card.Title>
                <Card.Text className="text-muted text-start">
                  ผู้ใช้บริการตกลงว่าจะไม่ใช้ Sender Name ในเอกสารนี้เพื่อวัตถุประสงค์ที่ไม่เหมาะสม ผิดศีลธรรม ฉ้อฉล ผิดกฎหมาย หรือเป็นการล่วงละเมิดในรูปแบบใด ๆ เช่น การคุกคาม การหลอกลวง หรือทำให้เกิดความเดือนร้อนแก่ผู้อื่น
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Card.Title className="text-primary">4. ข้อกำหนดเพิ่มเติม</Card.Title>
                <Card.Text className="text-muted text-start">
                  ผู้ใช้บริการจะไม่ก่อให้เกิดหรืออนุญาตให้ผู้อื่นกระทำการใด ๆ ที่เป็นการก่อกวนหรือรบกวนการใช้เครือข่ายของบริษัท และการกระทำดังกล่าวอาจนำไปสู่การยกเลิกการให้บริการโดยไม่ต้องแจ้งให้ทราบ
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Card.Title className="text-primary">5. ความเสียหายและความรับผิดชอบ</Card.Title>
                <Card.Text className="text-muted text-start">
                  ผู้ใช้บริการทราบว่าการไม่ปฏิบัติตามเงื่อนไขในเอกสารนี้อาจก่อให้เกิดความเสียหายต่อบริษัท JP Digital Agency Co., Ltd. ซึ่งผู้ใช้บริการต้องรับผิดชอบตามความเสียหายที่เกิดขึ้น
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Terms;