import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Image, Table, Form, Modal } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext';
import DashboardNavbar from './DashboardNavbar';
import avatar_logo from '../assets/user_avatar.png';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import api from '../api';

const OrderLog = () => {
  const { user } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState({});
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.get(`/user-info/${user.id}`);
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const fetchOrders = async () => {
        try {
          const response = await api.get(`/orders/${user.email}`);
          setFilteredOrders(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            Swal.fire('Error', 'Your session has expired. Please log in again.', 'error');
          } else {
            console.error('Failed to fetch orders', error);
          }
        }
    };

    if (user && user.id) {
        fetchUserInfo();
        fetchOrders();
    }
  }, [user]);

  return (
    <>
      <DashboardNavbar />
      <Container fluid className="p-4" style={{ backgroundColor: '#F9FAFB' }}>
        <Row className="mb-4">
          <Col>
            <h2 className="text-primary text-center">ประวัติการสั่งซื้อ</h2>
          </Col>
        </Row>
        <Row>
            <div className="table-responsive">
                <Table striped bordered hover className="custom-table">
                    <thead>
                        <tr>
                        <th>ไอดี</th>
                        <th>ชื่อแพ็คเกจ</th>
                        <th>ราคา</th>
                        <th>เครดิตที่ได้รับ</th>
                        <th>อีเมล</th>
                        <th>วันที่</th>
                        <th>สถานะ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map((order) => (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{order.plan_name}</td>
                            <td>{order.price}</td>
                            <td>{order.credits_received}</td>
                            <td>{order.contact_email}</td>
                            <td>{dayjs(order.order_date).format('LLL')}</td>
                            <td>{order.status_code === 1 ? "อนุมัติ" : "รอดำเนินการ"}</td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
        </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderLog;